<template>
  <div>
    <aw-spinner />
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { navigateTo, defineNuxtComponent, useRoute } from 'nuxt/app';
  import { fetchKey } from '~~/common/utils/awRunLegacyAsyncData.js';

  import { useOrderStore } from '~~/common/stores/order';
  import { useCheckoutStore } from '~~/common/stores/checkout';
  import { PAYMENT_METHODS } from '~/awPlugins/app-config.js';

  export default defineNuxtComponent({
    name: 'CetelemPaymentReturn',
    components: { AwSpinner: defineAsyncComponent(() => import('~~/common/components/Common/AwSpinner')) },
    fetchKey,
    async asyncData ({
      $i18n,
      $api,
      $logger,
      $pinia,
    }) {
      const route = useRoute();
      const {
        status,
        dossierId,
        orderId,
      } = route.query;
      const orderStore = useOrderStore($pinia);
      const checkoutStore = useCheckoutStore($pinia);
      try {
        await $api.$post('/cetelem/credit_payment_return', {
          orderIdent: orderId,
        });
        const successOrderCart = await $api.$get(`/orders/${orderId}/cart`);
        const paymentData = await $api.$get(`/orders/${orderId}/payment_transactions`);

        const checkoutType = successOrderCart.splits[0]?.checkoutType;

        const cartData = successOrderCart.splits[0];
        orderStore.setPaymentData({
          paymentData,
          checkoutType,
        });
        await orderStore.fetchOrder({
          id: 'current',
          fetchNonfoodCheckout: false,
          fetchFoodCheckout: false,
        });
        await checkoutStore.fetchCheckout({
          type: checkoutType,
          canStartSecondAction: true,
          canRunSimultaneous: true,
        });

        (checkoutType === 'food' ? orderStore.setCompletedFoodOrder : orderStore.setCompletedNonFoodOrder)({
          ...cartData,
          orderIdent: orderId,
          showCardInfo: true,
          creditStatus: status,
          creditDossierId: dossierId,
          paymentMethod: PAYMENT_METHODS.CREDIT,
        });

        return {
          orderIdent: orderId,
          checkoutType: checkoutType.toLowerCase(),
          creditStatus: status,
          creditDossierId: dossierId,
          paymentMethod: PAYMENT_METHODS.CREDIT,
        };
      } catch (e) {
        $logger.error(e);
        const locale = $i18n.defaultLocale === $i18n.locale.value ? '' : $i18n.locale.value;
        navigateTo(`/${locale}`);
      }
      return {};
    },
    mounted () {
      const orderStore = useOrderStore();
      navigateTo({
        path: this.localePath(`/shop/checkout-success/${this.orderIdent}`),
        query: {
          'creditStatus': this.creditStatus,
          ...(this.creditDossierId ? { creditDossierId: this.creditDossierId } : {}),
          ...(orderStore.editedOrder ? { edited: true } : {}),
        },
      });
    },
  });
</script>
