import { uuid4 } from '~~/common/utils/index.js';

export const COOKIE_TOKEN_EXPIRED = 'tokenExpiryLogout';

export function handleRedirectAfterTokenExpired ($i18n, $cookies, navigateTo) {
  const locale = $i18n.defaultLocale === $i18n.locale.value ? '' : $i18n.locale.value;
  $cookies.set(COOKIE_TOKEN_EXPIRED, true, {
    path: '/',
    maxAge: 30,
    sameSite: true, // strict
  });
  // NOTE: t query param is added to force the redirect by changing the url
  navigateTo({ path: `/${locale}`, query: { t: uuid4() } });
}
